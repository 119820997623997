<template>
  <template v-if="loading">
    <ProgressSpinner/>
  </template>
  <template v-else>
    <img :src="'../../images/jmf_logo.jpg'" style="height:100px"/>
    <span class="spectacle-list-title roboto-slab">Liste des spectacles</span>
    <!-- <MDBRow :cols="['1','md-2']" class="g-4 no-side-margin mt-2">
       <template v-for="(spectacle) of spectacles" v-bind:key="spectacle.id">
         <MDBCol>
           <MDBCard>
             <MDBCardImg :src="'data:image/jpeg;base64,' +spectacle.picture.content" top alt="..."/>
             <MDBCardBody>
               <MDBCardTitle>{{spectacle.name}}</MDBCardTitle>
               <MDBCardText>
                 {{spectacle.description}}
               </MDBCardText>
             </MDBCardBody>
           </MDBCard>
         </MDBCol>
       </template>
     </MDBRow>-->

    <div class="ml-5 mr-5">
      <HorizontalList :items="spectacles" :options="{
    responsive: [
      { end: 576, size: 1 },
      { start: 576, end: 768, size: 2 },
      { size: 2 },
    ],
  }">
        <template v-slot:default="{item}">
          <div class="card spectacle-list">
            <div class="image-container">
              <div class="image-content">
                <img :src="'data:image/jpeg;base64,' +item.picture.content"/>
              </div>
            </div>
            <div class="content-container">
              <div class="row mt-2">
                <div class="col-md-7 roboto-slab" style="position: relative;">
                  <h2>
                    <span>{{ item.name }}</span>
                    <span style="font-size: 1.5rem;"></span>
                  </h2>
                  <div class="mt-2"  style="position: absolute;">
                    <h5> Ville : <span class="roboto-slab">{{ item.salle.city }}📍</span></h5>
                    <h5>Salle : <span class="roboto-slab">{{ item.salle.name }}📍</span></h5>
                  </div>
                </div>

                <div class="col-md-5 mt-2 roboto-slab">

                  <h5 v-if="item.type ==='Inscription interne'"  class="col-md-12 text-center">Cliquez sur une date pour réserver</h5>

                  <h5 v-else class="col-md-12 text-center">Cliquez sur une des dates pour vérifier les modalités d'incscription</h5>
                    <div class="row">

                      <div class="col-md-12">
                        <div class="text-center">
                          <template v-for="(date) in item.spectacleDates" v-bind:key="date">
                            <a :href="goToRegisterPage(item.spectacleId, date.dateSpectacleId)" target="_blank">
                              <Button class="align-content-center roboto-slab spaced-button fixed-width-button mb-2 mt-3">
                                {{ formatDateTime(date.dateSpectacle) }}
                              </Button>
                            </a>
                          </template>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="item roboto-slab">
                <hr>
                <p>{{ item.description }}</p>
                <p v-if="item.salle.isHandicapped">♿ Accès handicapé</p>
                <p v-else>❌ Pas d'accès handicapé</p>
              </div>

              <div class="footer float-right mt-3">
                <a :href="item.webLink" target="_blank">
                  <Button label="Plus d'infos" class="mr-2 jm-red" icon="pi pi-plus" :href="item.webLink"/>
                </a>
              </div>
            </div>
          </div>
        </template>
      </HorizontalList>

    </div>
    <footer class="horizontal-footer">
      contact : jmfrancereseaux@gmail.com
    </footer>
  </template>

</template>

<script>

import SpectacleService from "@/service/SpectacleService";
import randomRef from "@mixins/randomRef";
import 'mdb-vue-ui-kit/css/mdb.min.css';
import {formatDateTime} from "/src/utils/utils.js";

export default {
  name: "SpectacleList",

  components: {},

  mixins: [randomRef],

  data() {
    return {
      loading: true,
      spectacles: []
    }
  },

  spectacleService: null,

  created() {
    this.spectacleService = new SpectacleService();
  },

  mounted() {
    this.spectacleService.comingSpectacles().then(data => {
      this.spectacles = data
      this.loading = false
    })
  },

  methods: {
    formatDateTime,
    goToRegisterPage(specId, dateId){
      return "/public/spectacle/"+specId+"/register/"+dateId;
      //route.push({name: "spectacleRegister", params: { spectacleId: specId }});
    },
  },
}

</script>

<style lang="scss">
body {
  background-color: #F0F1E9;
}

.image-content {
  img {
    width: 100%;
  }
}
</style>
